.page-background {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page-content-container {
    height: calc(100vh - 64px);
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.player-and-court-container {
    height: 100%;
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.player-container {
    height: 15%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* Apply styles to the scrollbar */
::-webkit-scrollbar {
    width: 12px; /* Set the width of the scrollbar */
    background-color: #f1f1f1; /* Background of the scrollbar track */
}

/* The scrollbar thumb */
::-webkit-scrollbar-thumb {
    background-color: #888; /* Solid color for the scrollbar thumb */
    border-radius: 6px; /* Rounded corners for the thumb */
}

/* Hover effect on the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Change color when hovered */
}


