.court-and-out-of-bounds {
    min-width: 350px;
    width: 100%;
    aspect-ratio: 1 / 2;
    display: flex;
    flex-direction: column;
    background-color: #8f8665;
    cursor: pointer;
}

.long-out-of-bounds {
    height: 6%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.long-out-of-bounds-subsection {
    height: 100%;
    width: 50%;
    box-sizing: border-box;
}

.court-and-wide-out-of-bounds {
    height: 88%;
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    border: 2px solid black;
}

.wide-out-of-bounds {
    height: 100%;
    width: 7%;
    display: flex;
    flex-direction: column;
}

.side-wide-out-of-bounds {
    height: 50%;
    width: 100%;
    box-sizing: border-box;
}

.side-wide-out-of-bounds-subsection {
    height: 50%;
    width: 100%;
    box-sizing: border-box;
}

.clickable-court-section {
    cursor: pointer;
    background-color: #c2b280;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect for non-touch devices */
@media (hover: hover) and (pointer: fine) {
    .clickable-court-section:hover {
        background-color: #55a5f0;
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
    }
}

/* Disable hover effect for touch devices */
@media (hover: none) and (pointer: coarse) {
    .clickable-court-section {
        transition: none;
    }
}

.in-bounds-court {
    height: 100%;
    width: 86%;
    box-sizing: border-box;
    border-left: 2px solid black;
    border-right: 2px solid black;
}

.shot-location-grid {
    height: 50%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 0;
    box-sizing: border-box;
    border-bottom: 2px solid black;
}

.shot-location-section {
    height: 100%;
    width: 100%;
    border-radius: 0;
    box-sizing: border-box;
    border: 1px solid black;
}

.shot-location-grid > div:nth-child(1) {
    border-top: none;
    border-left: none;
}
.shot-location-grid > div:nth-child(2) {
    border-top: none;
}
.shot-location-grid > div:nth-child(3) {
    border-top: none;
}
.shot-location-grid > div:nth-child(4) {
    border-top: none;
}
.shot-location-grid > div:nth-child(5) {
    border-top: none;
    border-right: none;
}
.shot-location-grid > div:nth-child(6) {
    border-left: none;
}
.shot-location-grid > div:nth-child(10) {
    border-right: none;
}
.shot-location-grid > div:nth-child(11) {
    border-left: none;
    border-bottom: none;
}
.shot-location-grid > div:nth-child(12) {
    border-bottom: none;
}
.shot-location-grid > div:nth-child(13) {
    border-bottom: none;
}
.shot-location-grid > div:nth-child(14) {
    border-bottom: none;
}
.shot-location-grid > div:nth-child(15) {
    border-right: none;
    border-bottom: none;
}

.attack-locations-and-free {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-top: 2px solid black;
}

.on-and-off-attack-locations {
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.on-and-off-attack-location {
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
}

.on-attack-location {
    position: relative;
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
}
.on-and-off-attack-locations > .on-and-off-attack-location:nth-child(1) {
    border-right: 2px solid black;
    border-right: 2px solid black;
}
.on-and-off-attack-locations > .on-and-off-attack-location:nth-child(2) {
    border-right: 1px solid black;
}
.on-and-off-attack-locations > .on-and-off-attack-location:nth-child(3) {
    border-right: 1px solid black;
    border-left: 1px solid black;
}
.on-and-off-attack-locations > .on-and-off-attack-location:nth-child(4) {
    border-left: 1px solid black;
}
.on-and-off-attack-locations > .on-and-off-attack-location:nth-child(5) {
    border-left: 2px solid black;
}

.off-attack-location {
    position: relative;
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    border-top: 2px solid black;
}

.attack-free-ball {
    height: 30%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    border-top: 2px solid black;
}

.serve-receive-grid {
    height: 50%;
    width: 100%;
    display: grid;
    border-top: 2px solid black;
    box-sizing: border-box;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 0;
}

.serve-receive-section {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid black;
}

.serve-receive-grid > div:nth-child(1) {
    border-top: none;
    border-left: none;
}
.serve-receive-grid > div:nth-child(2) {
    border-top: none;
}
.serve-receive-grid > div:nth-child(3) {
    border-top: none;
}
.serve-receive-grid > div:nth-child(4) {
    border-top: none;
    border-right: none;
}
.serve-receive-grid > div:nth-child(5) {
    border-left: none;
}
.serve-receive-grid > div:nth-child(8) {
    border-right: none;
}
.serve-receive-grid > div:nth-child(8) {
    border-right: none;
}
.serve-receive-grid > div:nth-child(9) {
    border-bottom: none;
    border-left: none;
}
.serve-receive-grid > div:nth-child(10) {
    border-bottom: none;
}
.serve-receive-grid > div:nth-child(11) {
    border-bottom: none;
}
.serve-receive-grid > div:nth-child(12) {
    border-bottom: none;
    border-right: none;
}

.rotate-180 {
    transform: rotate(180deg);
}