.page-background {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page-content-container-stat-court {
    height: calc(100vh - 64px);
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.player-and-court-container-stat-court {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.player-container-stat-court {
    height: 15%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.court-and-button-gutters-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.conditional-buttons-gutter {
    height: 100%;
    width: 175px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px
}

/* Apply styles to the scrollbar */
::-webkit-scrollbar {
    width: 12px; /* Set the width of the scrollbar */
    background-color: #f1f1f1; /* Background of the scrollbar track */
}

/* The scrollbar thumb */
::-webkit-scrollbar-thumb {
    background-color: #888; /* Solid color for the scrollbar thumb */
    border-radius: 6px; /* Rounded corners for the thumb */
}

/* Hover effect on the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Change color when hovered */
}

/* Click effect */
.click-effect:active {
    transform: scale(0.98);
}
